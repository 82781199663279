import moment from "moment";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";

import Loader from "../../components/global/loader/Loader";
import Header from "../../components/header/Header";
import SlotModalTrigger from "../../components/slotManagement/SlotModalTrigger";
import TablePagination from "../../components/table/TablePagination";

import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import { ReactComponent as ClearIcon } from "../../assets/icons/cancel.svg";
import SearchIcon from "../../assets/icons/search-no-bg.svg";

const ManageSlots = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [slots, setSlots] = useState([]);
  const adminimspin = useSelector(
    (state) => state.loginData.LoginDetails.userImsPin
  );
  const getSlots = () => {
    return apirequest({
      url: `${endpoint.getSlots.addr}`,
      method: "post",
      data: {
        adminimspin: adminimspin,
        asset_id: Number(id),
      },
    });
  };
  const { data, isLoading, isError } = useQuery({
    queryKey: [endpoint.getSlots.key],
    queryFn: getSlots,
    select: (data) => data.data.data,
    onSuccess: (data) => {
      setSlots(data);
    },
  });

  const dataPerPage = 10;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const thClassName = "text-lg font-semibold font-ibm";

  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  const handleSearch = (value) => {
    if (value === "") {
      setSlots(data);
    }
    setSlots(() =>
      data.filter(
        (slot) =>
          slot.venue_name.toLowerCase().includes(value.toLowerCase()) ||
          slot.venue_name.toLowerCase() === value.toLowerCase()
      )
    );
  };

  const downloadReportHandler = () => {
    return axios.post(
      "https://staging.myimsv3.imsindia.com:5053/cms/courseware/assets/slots/slotBookingReport",
      { adminimspin, assetId: Number(id) },
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );
  };

  const { mutate, isLoading: isDownloading } = useMutation({
    mutationFn: downloadReportHandler,
    onSuccess: (response) => {
      const contentDisposition = response.request.getResponseHeader(
        "content-disposition"
      );
      const filename = contentDisposition
        ?.split("filename=")[1]
        .replace(/["']/g, "");
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(link);
      URL.revokeObjectURL(fileUrl);
    },
    onError: (error) => {
      if (error.response.status === 404) {
        alert("Report not found.");
      }
    },
  });

  return (
    <div>
      <Header />
      <div className="flex justify-between pt-5 md:ml-[4%] md:mr-[34px]">
        <div
          className={`z-50 ${"min-w-[26rem] "} rounded-2xl shadow-outer flex p-[1rem]  relative bg-white `}
        >
          <div className={`${"w-full"} flex  items-center`}>
            <input
              type="text"
              name="search"
              value={filterKeyword}
              placeholder={"Search by venue name"}
              onChange={(e) => {
                setFilterKeyword(e.target.value);
                handleSearch(e.target.value);
              }}
              autoFocus
              className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
              autoComplete="off"
            />
            <ClearIcon
              className="mr-5 cursor-pointer"
              onClick={() => {
                setFilterKeyword("");
                handleSearch("");
              }}
            />
          </div>
          <div className="h-5 w-5 flex items-center justify-end">
            <img
              src={SearchIcon}
              className="cursor-pointer"
              alt="search"
              onClick={() => handleSearch(filterKeyword)}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={mutate}
            className="p-4 bg-primary-200 text-white rounded-xl"
          >
            {isDownloading ? (
              <SpinnerCircular
                size={16}
                thickness={12}
                speed={100}
                color="#fff"
                secondaryColor="#f3f4f6"
              />
            ) : (
              "Download Report"
            )}
          </button>
          <SlotModalTrigger />
        </div>
      </div>
      <div className=" flex flex-col gap-4 md:ml-[4%] md:mr-[34px] mt-4 shadow-outer rounded-xl bg-white pb-[2%] pt-5">
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <h3 className="text-bold text-red-500 text-lg">
            Error Loading tests List...
          </h3>
        ) : (
          <>
            <table className={`border-separate w-full `}>
              <thead className="shadow-outer w-full h-[4rem] top-0 z-50 text-left">
                <tr className="w-full">
                  <th className={thClassName + " pl-5"}>Venue</th>
                  <th className={thClassName}>
                    Date
                    {/* <span className="ml-1 cursor-pointer font-medium relative">
                  <span className="absolute left-0 opacity-40">&#8593;</span>
                  <span className="absolute left-2 opacity-40">&#8595;</span>
                </span> */}
                  </th>
                  <th className={thClassName + ""}>Time Slot</th>
                  <th className={thClassName + ""}>Total Terminals</th>
                  <th className={thClassName + ""}>Booked Terminals</th>
                  <th className={thClassName}></th>
                </tr>
              </thead>
              <tbody className="table-spacing">
                {slots && slots?.length == 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      className="py-20 px-10 text-center text-xl font-ibm opacity-80"
                    >
                      No Data Found!
                    </td>
                  </tr>
                ) : (
                  <>
                    {slots
                      ?.slice(indexOfFirstItem, indexOfLastItem)
                      .map((slot, index) => (
                        <tr key={index}>
                          <td className="align-top pt-4 pl-3">
                            <div className={`flex `}>
                              <div
                                className={`align-top`}
                                // onClick={() => "userId" in key && onclickHandler(key)}
                              >
                                <p className={"text-xl mb-0 "}>
                                  {slot.venue_name}
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="align-top pt-4">
                            <p className={"text-xl mb-0 opacity-100"}>
                              {moment(slot.slot_date).format("MMM DD YYYY")}
                            </p>
                          </td>
                          <td className="align-top pt-4">
                            <p className={"text-xl mb-0 opacity-100"}>
                              {moment(
                                slot.slot_time_start.split(" ")[1],
                                "HH:mm"
                              ).format("hh:mm A")}{" "}
                              -{" "}
                              {moment(
                                slot.slot_time_end.split(" ")[1],
                                "HH:mm"
                              ).format("hh:mm A")}
                            </p>
                          </td>
                          <td className="align-top pt-4">
                            <p className={"text-xl mb-0 opacity-100"}>
                              {slot.num_terminals_available}
                            </p>
                          </td>
                          <td className="align-top pt-4">
                            <p className={"text-xl mb-0 opacity-100"}>
                              {slot.booked_terminals}
                            </p>
                          </td>
                          <td className="align-top pt-4">
                            {/* {slot.booked_terminals === 0 && ( */}
                            <SlotModalTrigger isEdit={true} slotToEdit={slot} />
                            {/* )} */}
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
            {slots.length !== 0 && (
              <TablePagination
                data={slots}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ManageSlots;
